/* eslint-disable no-param-reassign */
/* eslint-disable consistent-return */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { css, keyframes } from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import checkIcon from 'assets/icons/confirm.svg';
import errorIcon from 'assets/icons/close-red.svg';
import arrowDownIcon from 'assets/icons/arrow-down.svg';
import Icon from 'components/shared/Icon';
import getFormParams from 'browser/utils/getFormParams';
import getCookie from 'browser/utils/getCookie';
import SuccessInfo from 'components/shared/SuccessInfo';
import Button from 'components/shared/Button';
import emitMatomoEvent, {
  eventsNames,
  getVisitorId,
} from 'utils/emitMatomoEvent';
import { getRoomsParam } from 'utils/getRoomsParam';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledForm = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
  color: #182a37;
  padding: 25px 0;
  background-color: ${({ theme }) => theme.colors.lightGreen300};
  padding: 0 10%;
`;

const FormHeading = styled.h2`
  width: 100%;
  margin: 40px 0;
  color: #182a37;
  font-size: ${({ theme }) => theme.fontSize.m};
  text-transform: uppercase;
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    font-size: ${({ theme }) => theme.fontSize.md};
  }
  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.fontSize.lg};
  }
  ${({ theme }) => theme.mq.xxl} {
    font-size: ${({ theme }) => theme.fontSize.xl};
  }
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 30px;
  color: ${({ theme, error }) =>
    error ? theme.colors.red100 : theme.colors.dark};
  font-size: 1.6rem;
  font-family: ${({ theme }) => theme.fonts.mainFont};
  overflow: visible;
  transition: 0.3s;
  border: 1px solid ${({ theme }) => theme.colors.gray100};
  &:focus {
    color: ${({ theme }) => theme.colors.dark} !important;
    border-color: ${({ theme }) => theme.colors.green} !important;
  }
  &::placeholder {
    opacity: 0.8;
    color: ${({ theme }) => theme.colors.dark};
    text-align: left;
  }
  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ theme }) => theme.mq.lg} {
    width: 23.5%;
    padding: 12px 20px;
  }
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 25px;
  position: relative;
  padding-left: 40px;
  margin: 15px 0;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.fontSize.xs};
  user-select: none;
  line-height: 18px;
  ${({ theme }) => theme.mq.xs} {
    padding-left: 50px;
    font-size: ${({ theme }) => theme.fontSize.s};
  }
  b {
    color: #182a37;
    margin-left: -10px;
    margin-bottom: 2px;
  }
`;

const RoomLabel = styled(Label)`
  padding-left: 28px !important;
  font-size: 1.7rem !important;
  margin: 10px 20px 15px 0;

  /* :first-child {
    cursor: not-allowed;

    * {
      cursor: not-allowed;
    }
  } */

  ${({ theme }) => theme.mq.xs} {
    margin: 15px 20px 20px 10px;
    padding-left: 35px !important;
  }
`;

const RoomText = styled.p`
  position: absolute;
  top: 1px;
`;

const LabelBtn = styled.button`
  background: transparent;
  cursor: pointer;
  align-self: flex-end;
  transition: 0.1s;
  ${({ inverted }) =>
    inverted &&
    css`
      transform: rotate(180deg);
    `}
  ${({ theme }) => theme.mq.lg} {
    margin-top: 10px;
    margin-right: -5px;
  }
`;

const LabelText = styled.p`
  font-size: 1.2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  font-weight: ${({ theme }) => theme.light};
  align-self: flex-start;
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
    `}

  a {
    color: inherit;
    font-weight: 600;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red100 : theme.colors.gray100};
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 3px;
    top: 3px;
    width: 12px;
    height: 12px;
    background: url(${checkIcon}) no-repeat center;
  }
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const FormFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
  font-weight: ${({ theme }) => theme.light};
  & > button {
    margin: 0 auto;
  }
  ${({ theme }) => theme.mq.lg} {
    flex-direction: row;
    justify-content: space-between;
    margin: 40px 0 20px;
  }
`;

const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > span {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 25px;
    }
  }
`;

const ErrorMessage = styled.span`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.colors.red100};
  line-height: 18px;
  &:before {
    content: '';
    margin-top: 1px;
    margin-right: 10px;
    width: 12px;
    height: 12px;
    background: url(${errorIcon}) no-repeat center;
    background-size: 12px 12px;
  }
`;

const MessageContent = styled(ErrorMessage)`
  opacity: ${({ color }) => (color ? '1' : '0')};
  visibility: ${({ color }) => (color ? 'visible' : 'hidden')};
  ${({ color }) =>
    color === 'red' &&
    css`
      color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ color }) =>
    color === 'green' &&
    css`
      color: green;
      &:before {
        display: none;
      }
    `}
`;

const Heading = styled.span`
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.m};
  font-weight: ${({ theme }) => theme.light};
  padding-top: 5px;
`;

const RoomCheckboxesWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const TermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 0 25px;
  border-top: 1px solid #182a37;
  border-bottom: 1px solid #182a37;

  ${({ theme }) => theme.mq.lg} {
    align-items: flex-start;
  }
`;

const SingleTermsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  ${({ theme }) => theme.mq.lg} {
    display: grid;
    & > label {
      margin: 10px 0;
      padding-left: 32px;
    }
  }

  & > label {
    &:nth-child(2),
    &:nth-child(3) {
      margin-left: 12px;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: stretch;
  width: 70%;
  height: 42px;
  min-width: 220px;
  max-width: 540px;
  margin: 0 auto;
  position: relative;
  ${({ theme }) => theme.mq.s} {
    height: 50px;
  }
  ${({ theme }) => theme.mq.lg} {
    width: 35%;
  }
`;

const StyledButton = styled(Button)`
  position: relative;
  display: block;
  padding: 12px 28px;
  color: ${({ theme }) => theme.colors.lightYellow};
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.bold};
  font-size: 1.4rem;
  width: 100%;
  max-width: unset;
  margin: 0;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  text-transform: uppercase;
  text-decoration: none;
  transition: 0.3s;
  text-align: center;
  letter-spacing: 1px;
`;

const ButtonLoader = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  left: calc(50% - 12px);
  width: 24px;
  height: 24px;
  border: 3px solid #fff;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-radius: 50%;
  background: none;
  opacity: ${({ isSubmitting }) => (isSubmitting ? '1' : '0')};
  animation: ${spin} 1s ease infinite;
  z-index: 5;
`;

const getYLdata = (name, isClassName = true) => {
  if (isClassName) {
    switch (name.toLowerCase()) {
      case 'term1':
        return 'yl-oferta';
      case 'term2':
        return 'yl-oferta_handlowa_bezposrednia-telefon';
      case 'term3':
        return 'yl-oferta_handlowa';
      case 'term4':
        return 'yl-oferta_handlowa_elektroniczna-email';
      default:
        return null;
    }
  } else {
    switch (name.toLowerCase()) {
      case 'term1':
        return 'acceptance-729';
      case 'term2':
        return 'acceptance-186';
      case 'term3':
        return 'acceptance-448';
      case 'term4':
        return 'acceptance-903';
      default:
        return null;
    }
  }
};

const AskForm = () => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [expandedTerms, setExpandedTerms] = useState([]);
  const [formMessage, setFormMessage] = useState({ text: '', color: '' });
  const [roomsNumber, setRoomsNumber] = useState([]);
  const [fillEventStarted, setFillEventStarted] = useState(false);
  const [fillEventEmitted, setFillEventEmitted] = useState(false);

  const term1Key = 'term1';
  const term2Key = 'term2';
  const term3Key = 'term3';
  const term4Key = 'term4';
  const acceptAllKey = 'acceptAll';

  useEffect(() => {
    if (fillEventStarted && fillEventEmitted) {
      emitMatomoEvent({
        event: eventsNames.formularz_gora_wypelnianie_start,
      });
    }
  }, [fillEventStarted, fillEventEmitted]);

  useEffect(() => {
    setFillEventStarted(false);
    setFillEventEmitted(false);
  }, []);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Proszę o podanie imienia'),
    surname: Yup.string(),
    email: Yup.string()
      .email('Adres email jest nieprawidłowy')
      .required('Proszę o podanie adresu email'),
    phone: Yup.string()
      .min(9, 'Numer telefonu jest nieprawidłowy')
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        'Numer telefonu jest nieprawidłowy'
      )
      .required('Proszę podać numer telefonu'),
    [term4Key]: Yup.bool().oneOf([true], 'Proszę o zaznaczenie zgód'),
  });

  // const {
  //   wpPage: {
  //     homepage: { homepageContactTerms },
  //   },
  // } = useStaticQuery(graphql`
  //   query queryForm {
  //     wpPage {
  //       homepage {
  //         homepageContactTerms {
  //           homepageContactTermsName
  //           homepageContactTermsRequired
  //           homepageContactTermsText
  //         }
  //       }
  //     }
  //   }
  // `);

  const terms = [
    {
      name: 'term1',
      content:
        'Chcę otrzymywać od Matexi Polska Sp. z o.o. informacje o promocjach, ofertach i inne informacje handlowe, co do produktów i usług oferowanych przez spółki z Grupy Matexi za pośrednictwem:',
      required: false,
    },
    {
      name: 'term2',
      content: 'poczty elektronicznej (e-mail)',

      required: false,
    },
    {
      name: 'term3',
      content: 'telefonu (w tym SMS, MMS)',
      required: false,
    },
    {
      name: 'term4',
      content:
        'Zapoznałem/am się z <a href="/polityka-prywatnosci">Polityką Prywatności Matexi Polska Sp. z o.o.</a>*',
      required: true,
    },
  ];

  const formTerms = terms.reduce((obj, item) => {
    obj[item.name] = false;
    return obj;
  }, {});

  const handleCheckbox = name => {
    if (expandedTerms.includes(name)) {
      setExpandedTerms(expandedTerms.filter(term => term !== name));
    } else setExpandedTerms([...expandedTerms, name]);
  };

  const handleRoomsNumber = roomNumber => {
    if (roomsNumber.includes(roomNumber)) {
      setRoomsNumber(roomsNumber.filter(term => term !== roomNumber));
    } else setRoomsNumber([...roomsNumber, roomNumber]);
  };

  const handleReset = () => {
    setSubmitSuccess(false);
    setSubmitClicked(false);
    setFormMessage({ text: '', color: '' });
    setRoomsNumber([]);
  };

  const roomOptions = ['1', '2', '3', '4', 'inne'];

  return (
    <Formik
      initialValues={{
        name: '',
        surname: '',
        email: '',
        phone: '',
        [acceptAllKey]: false,
        ...formTerms,
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const sendEmail = async () => {
          const {
            name,
            surname,
            email,
            phone,
            [term1Key]: agree1,
            [term2Key]: agree2,
            [term3Key]: agree3,
            [term4Key]: agree4,
          } = values;

          let params = {
            name,
            surname,
            agree1,
            agree2,
            agree3,
            agree4,
            url: window.location.href,
          };
          params = email ? { ...params, email } : params;
          params = phone ? { ...params, phone } : params;
          roomsNumber.length !== 0
            ? (params = { ...params, rooms: roomsNumber.sort().join() })
            : params;
          if (terms.some(term => term.required && values[term.name] === false))
            return setSubmitting(false);

          try {
            setSubmitting(true);

            const rooms = getRoomsParam({
              room1: roomsNumber.includes('1'),
              room2: roomsNumber.includes('2'),
              room3: roomsNumber.includes('3'),
              room4: roomsNumber.includes('4'),
              roomOther: roomsNumber.includes('inne') ? 'inne' : false,
            });

            const formData = new FormData();

            formData.append('firstname', name);
            formData.append('surname', surname);
            formData.append('phone', phone);
            formData.append('email', email);
            formData.append('rooms', rooms);
            formData.append('consent1a', String(agree2));
            formData.append('consent1b', String(agree3));
            formData.append('consent2', String(agree4));
            formData.append('type', 'Kontakt (góra)');
            formData.append('source', 'Kameralny Prokocim 2');

            if (typeof window !== 'undefined') {
              formData.set('url', window.location.href);
            }

            const { data } = await axios.post(
              'https://matexicms.indigital.pl/mailer/mailer-main.php',
              formData
            );

            if (data.status === 'error') {
              throw new Error('error');
            }

            if (window.Monitor)
              window.Monitor.TrackForm('contactFormTop', 3, 3);

            let visId = getCookie('m_vis_id');

            if (!visId) {
              const savedVisitorId = getVisitorId();
              visId = savedVisitorId;
            }

            const CRMFormParams = {
              ...getFormParams(params),
              leadID: data.leadId,
              visitorID: visId,
              form_name: 'lp-form-kontakt-top',
              id_investment: '46',
            };
            const { data: CRMData } = await axios.post(
              `https://api.matexipolska.pl/export/api_makeform/?mode=json`,
              {
                ...CRMFormParams,
              }
            );

            console.log('CRMFormParams', CRMFormParams);
            console.log('CRMData', CRMData);

            console.log('CRMData', CRMData);

            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'formularz',
                mieszkanie: '',
              });
            }
            if (window._mtm) {
              window._mtm.push({
                event: 'formularz',
                mieszkanie: '',
              });
            }

            emitMatomoEvent({
              event: eventsNames.formularz_gora_wyslany,
              lead_id: data.leadId,
            });

            setSubmitting(false);
            setFormMessage({
              text: data.message || 'Wiadomość wysłana pomyślnie!',
              color: 'green',
            });

            setSubmitSuccess(true);

            setTimeout(() => {
              handleReset();
              resetForm();
            }, 10000);
          } catch (err) {
            emitMatomoEvent({
              event: eventsNames.formularz_gora_blad_wysylki,
            });
            setSubmitting(false);
            setFormMessage({
              text: 'Coś poszło nie tak!',
              color: 'red',
            });

            setTimeout(() => {
              setFormMessage({ text: '', color: '' });
            }, 2500);
          }
        };
        sendEmail();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setErrors,
        setFieldValue,
        setFieldTouched,
        isSubmitting,
        resetForm,
      }) => (
        <StyledForm onSubmit={handleSubmit} id="contactFormTop">
          {submitSuccess ? (
            <SuccessInfo
              onClick={() => {
                handleReset();
                resetForm();
              }}
            />
          ) : (
            <>
              <FormHeading>zapytaj o mieszkanie</FormHeading>
              <InputsWrapper>
                <Input
                  id="name"
                  placeholder="Imię*"
                  onChange={handleChange}
                  onKeyDown={() => {
                    if (!fillEventStarted && !fillEventEmitted) {
                      setFillEventStarted(true);
                      setFillEventEmitted(true);
                    }
                  }}
                  onBlur={handleBlur}
                  error={submitClicked && errors.name && touched.name}
                  value={values.name}
                  touched={touched.name}
                  errors={errors.name}
                />
                <Input
                  id="surname"
                  placeholder="Nazwisko"
                  onChange={handleChange}
                  onKeyDown={() => {
                    if (!fillEventStarted && !fillEventEmitted) {
                      setFillEventStarted(true);
                      setFillEventEmitted(true);
                    }
                  }}
                  onBlur={handleBlur}
                  value={values.surname}
                  touched={touched.surname}
                />
                <Input
                  id="email"
                  placeholder="Adres email*"
                  onChange={handleChange}
                  onKeyDown={() => {
                    if (!fillEventStarted && !fillEventEmitted) {
                      setFillEventStarted(true);
                      setFillEventEmitted(true);
                    }
                  }}
                  onBlur={handleBlur}
                  error={submitClicked && errors.email && touched.email}
                  value={values.email}
                  touched={touched.email}
                  errors={errors.email}
                />
                <Input
                  id="phone"
                  placeholder="Telefon*"
                  onChange={handleChange}
                  onKeyDown={() => {
                    if (!fillEventStarted && !fillEventEmitted) {
                      setFillEventStarted(true);
                      setFillEventEmitted(true);
                    }
                  }}
                  onBlur={handleBlur}
                  error={submitClicked && errors.phone && touched.phone}
                  value={values.phone}
                  touched={touched.phone}
                  errors={errors.phone}
                />
              </InputsWrapper>
              <Heading>Ilość pokoi</Heading>
              <RoomCheckboxesWrapper>
                {roomOptions.map((room, index) =>
                  index > 0 ? (
                    <RoomLabel key={room} htmlFor={`askForm-${room}`}>
                      <RoomText>{room}</RoomText>
                      <Checkbox
                        id={`askForm-${room}`}
                        name={`yl-checkbox-pokoje_${index}`}
                        type="checkbox"
                        value={room}
                        onChange={() => handleRoomsNumber(room)}
                        checked={roomsNumber.includes(room)}
                      />
                      <Checkmark />
                    </RoomLabel>
                  ) : null
                )}
              </RoomCheckboxesWrapper>
              <TermsWrapper>
                {terms.length !== 0 && (
                  <Label htmlFor={`askForm-${acceptAllKey}`}>
                    <b>Zaznacz wszystkie zgody</b>
                    <Checkbox
                      id={`askForm-${acceptAllKey}`}
                      name={`askForm-${acceptAllKey}`}
                      type="checkbox"
                      onChange={() => {
                        if (!terms.every(term => values[term.name] === true)) {
                          setFieldValue(acceptAllKey, true);
                          terms.forEach(term => setFieldValue(term.name, true));
                        } else {
                          setFieldValue(acceptAllKey, true);
                          terms.forEach(term =>
                            setFieldValue(term.name, false)
                          );
                        }
                      }}
                      checked={terms.every(term => values[term.name] === true)}
                    />
                    <Checkmark />
                  </Label>
                )}
                <SingleTermsWrapper>
                  {terms.map(({ name, content, required }) => (
                    <Label htmlFor={`askForm-${name}`} key={content}>
                      <LabelText
                        expanded={expandedTerms.includes(name)}
                        dangerouslySetInnerHTML={{ __html: content }}
                      />
                      {/* <LabelBtn
                        type="button"
                        onClick={() => handleCheckbox(name)}
                        inverted={expandedTerms.includes(name)}
                      >
                        <Icon src={arrowDownIcon} size={12} />
                      </LabelBtn> */}
                      <Checkbox
                        id={`askForm-${name}`}
                        name={getYLdata(name, false)}
                        type="checkbox"
                        onChange={() => {
                          const isParentAgreement = name.includes('1');
                          const isSubAgreement =
                            name.includes('2') || name.includes('3');

                          if (!isParentAgreement && !isSubAgreement) {
                            setFieldValue(name, !values[name]);
                            if (required) {
                              setErrors({ ...errors, [term1Key]: '' });
                            }
                            return;
                          }

                          const parentAgreementSelected = values.term1;

                          if (isParentAgreement) {
                            if (!parentAgreementSelected) {
                              setFieldValue(`term1`, true);
                              setFieldValue(`term2`, true);
                              setFieldValue(`term3`, true);
                              setFieldTouched(`term1`, true);
                              setFieldTouched(`term2`, true);
                              setFieldTouched(`term3`, true);
                            } else {
                              setFieldValue(`term1`, false);
                              setFieldValue(`term2`, false);
                              setFieldValue(`term3`, false);
                              setFieldTouched(`term1`, false);
                              setFieldTouched(`term2`, false);
                              setFieldTouched(`term3`, false);
                            }
                          }

                          if (isSubAgreement) {
                            const isCurrentSubAgreementSelected = values[name];
                            const bothSubAgreementsSelected =
                              values.term2 && values.term3;

                            if (parentAgreementSelected) {
                              if (bothSubAgreementsSelected) {
                                setFieldValue(name, !values[name]);
                                setFieldTouched(name, true);
                                return;
                              }
                              if (isCurrentSubAgreementSelected) {
                                setFieldValue(`term1`, false);
                                setFieldValue(name, !values[name]);
                                setFieldTouched(name, true);
                                setFieldTouched(`term1`, false);
                              } else {
                                setFieldValue(name, !values[name]);
                                setFieldTouched(name, true);
                              }
                            } else if (!parentAgreementSelected) {
                              setFieldValue(`term1`, true);
                              setFieldValue(name, !values[name]);
                              setFieldTouched(`term1`, true);
                              setFieldTouched(name, true);
                            }
                          }
                        }}
                        checked={values[name]}
                      />
                      <Checkmark
                        error={
                          submitClicked &&
                          errors[term4Key] &&
                          required &&
                          values[name] === false
                        }
                      />
                    </Label>
                  ))}
                </SingleTermsWrapper>
              </TermsWrapper>
              <FormFooter>
                <ErrorsWrapper>
                  {submitClicked &&
                    errors.name &&
                    touched.name &&
                    errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                  {submitClicked &&
                    errors.email &&
                    touched.email &&
                    errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                  {submitClicked &&
                    errors.phone &&
                    touched.phone &&
                    errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
                  {submitClicked && errors[term4Key] && (
                    <ErrorMessage>{errors[term4Key]}</ErrorMessage>
                  )}
                  {formMessage.text && (
                    <MessageContent color={formMessage.color}>
                      {formMessage.text}
                    </MessageContent>
                  )}
                </ErrorsWrapper>
                <ButtonWrapper>
                  <StyledButton
                    onClick={() => setSubmitClicked(true)}
                    disabled={isSubmitting}
                    type="submit"
                  >
                    {isSubmitting ? '' : 'wyślij zapytanie'}
                  </StyledButton>
                  <ButtonLoader isSubmitting={isSubmitting} />
                </ButtonWrapper>
              </FormFooter>
            </>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

export default AskForm;
